<template>
    <div class="mobile-content">
        <div class="w-100p position-relative overflow-h">
            <img src="@/assets/image/index/bg.png" class="w-100p">
            <div class="mobile-content-bg">
                <div>企业技术服务提供者</div>
                <div>网络安全/软件开发/广告传媒/财税服务</div>
            </div>
        </div>
        <div>
            <div class="news-m-box">
                <div class="new-m-header">
                    <div :class="{'news-items-active':current == 1}" @click="navClick(1)">新闻动态</div>
                    <div :class="{'news-items-active':current == 2}" @click="navClick(2)">企业资讯</div>
                    <div :class="{'news-items-active':current == 3}" @click="navClick(3)">技术专栏</div>
                </div>
                <div class="d-flex d-flex-between m-b-10 m-t-10 p-l-15 p-r-15" v-for="(item,index) in list" :key="index" @click="jump(item)">
                    <div class="w-75p h-60 color-white ">
                        <div class="m-b-10 news-content-title-m f-14">{{item.title}}</div>
                        <div class="f-12">{{item.createtime}}</div>
                    </div>
                     <div class="w-110 h-110 overflot-h">
                        <img :src="item.image" class="w-110 h-110">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent,ref } from 'vue'
import baseRequest from '@/service/index'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const list = ref([])
        const current = ref(1)
        const router = useRouter()
        const jump = (item) =>{
            router.push({
                path:"/newsDetail",
                query:{
                    id:item.id
                }
            })
        }
        const getList = (type) =>{
            baseRequest.post({
                url: 'api/index/article',
                data:{
                    type
                }
            }).then(res=>{
                console.log(res)
                list.value = res.data.data
            })
        }
        getList(current.value)
        const navClick = (val) =>{
            current.value = val
            getList(current.value)
        }
        return{
            jump,list,current,navClick
        }
    }
});
</script>
