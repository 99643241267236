<template>
    <div class="group-bg-box">
        <div class="about-box">
            <div class="news-pc-title-box">
                <img src="@/assets/image/news/1.png" class="w-300 h-70">
                <div class="news-pc-title">
                    <div>新闻中心</div>
                    <div class="m-t-5">News Center</div>
                </div>
            </div>
            <div class="d-flex d-flex-between m-t-50">
                <div class="about-item-box w-40p">
                    <div class="about-item-swipers p-25">
                        <el-carousel height="400px"  @change="swiperChange" >
                            <el-carousel-item v-for="(item,index) in swiperList" :key="index" @click="jump(item)">
                                <img :src="item.image" class="w-100p h-400">
                            </el-carousel-item>
                        </el-carousel>
                        <div class="color-white"  @click="jump(swiperInfo)">
                            <div class="m-t-20">{{swiperInfo.title}}</div>
                            <div class="m-t-10">{{swiperInfo.createtime}}</div>
                        </div>
                    </div>
                </div>
                <div class="about-item-box w-58p">
                    <div class="news-items-box">
                        <div :class="{'news-items-active':current == 1}" @click="navClick(1)">新闻动态</div>
                        <div :class="{'news-items-active':current == 2}" @click="navClick(2)">企业资讯</div>
                        <div :class="{'news-items-active':current == 3}" @click="navClick(3)">技术专栏</div>
                    </div>
                    <div class="news-box">
                        <div class="d-flex m-b-20" v-for="(item,index) in list" :key="index" @click="jump(item)">
                            <div class="w-20p m-r-2p h-60 overflot-h">
                                <img :src="item.image" class="w-100p">
                            </div>
                            <div class="w-73p h-60 news-content">
                                <div class="news-content-title-box">
                                    <div class="news-content-title-desc" v-if="current == 1">新闻动态</div>
                                    <div class="news-content-title-desc" v-if="current == 2">企业资讯</div>
                                    <div class="news-content-title-desc" v-if="current == 3">技术专栏</div>
                                    <div class="news-content-title">{{item.title}}</div>
                                </div>
                                <div>{{item.createtime}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { defineComponent,ref } from 'vue'
import baseRequest from '@/service/index'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const list = ref([])
        const swiperList = ref([])
        const swiperInfo = ref({})
        const current = ref(1)
        const router = useRouter()
        const jump = (item) =>{
            router.push({
                path:"/newsDetail",
                query:{
                    id:item.id
                }
            })
        }
        const getList = (type) =>{
            baseRequest.post({
                url: 'api/index/article',
                data:{
                    type
                }
            }).then(res=>{
                console.log(res)
                list.value = res.data.data
            })
        }
        getList(current.value)
        const getSwiperList = () =>{
            baseRequest.post({
                url: 'api/index/banner',
            }).then(res=>{
                console.log(res,'轮播图')
                swiperList.value = res.data
                swiperInfo.value = res.data[0]
            })
        }
        getSwiperList()
        const navClick = (val) =>{
            current.value = val
            getList(current.value)
        }
        const swiperChange = (e)=>{
            console.log(e)
            swiperInfo.value = swiperList.value[e]
        }
        return{
            jump,list,current,navClick,swiperList,swiperChange,swiperInfo
        }
    }
});
</script>

<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
</style>
